<template>
  <div>
    <b-row>
        <b-col>
                <b-card no-body class="border">
      <b-card-header>
        <b-card-title>My Dashboards List</b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="text-center text-success" v-if="dashboards.length == 0">
          <b-spinner/>
        </div>
        <div v-else>
          <b-list-group>
            <b-list-group-item
              class="flex-column align-items-start"
              v-for="(i, index) in dashboards"
              :key="index"
              @click="getDash(i)"
               v-bind:class="{ active: selecionado == i }"
            >
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">
                  {{ i.title }}
                </h5>
                <small class="text-secondary"
                  >{{ i.layout.length }}
                  {{ i.layout.length == 1 ? 'Chart' : 'Charts' }}</small
                >
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-card-body>
    </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
                <grid-layout
          :key="changeDash"
            :layout.sync="layout"
            :col-num="12"
            :row-height="30"
            :is-draggable="false"
            :is-resizable="false"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[10, 12]"
            :use-css-transforms="true"
            :responsive="false"
            :breakpoints="breakpoints"
            :cols="cols"
            @layout-ready="layoutReady = true">

        <grid-item v-for="item in layout"
                   :static="item.static" 
                   :x="item.x"
                   :y="item.y"
                   :w="item.w"
                   :h="item.h"
                   :i="item.i"
                   :key="item.i">
      <apex-multipe-bar-chart :chartTitle="item.chartInfo.title" :cseries="item.chartInfo.cdata" 
      :labels="item.chartInfo.labels" :key="item.chartInfo.chartType.value" :color="['#00E396']" :idref="item.i"
      :eliminar="0" v-if="item.chartInfo.chartType.value==1"/>
  
           <apex-line-area-chart :chartTitle="item.chartInfo.title" :cseries="item.chartInfo.cdata" 
      :labels="item.chartInfo.labels" :key="item.chartInfo.chartType.value" :estado="true" :idref="item.i"
      :eliminar="0" v-if="item.chartInfo.chartType.value==2"/>
  
        <apex-line-area :chartTitle="item.chartInfo.title" :cseries="item.chartInfo.cdata" 
      :labels="item.chartInfo.labels" :key="item.chartInfo.chartType.value" :idref="item.i"
      :eliminar="0" v-if="item.chartInfo.chartType.value==3"/>
        </grid-item>
    </grid-layout>
        </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BSpinner,
  BRow,
  BCol
} from 'bootstrap-vue';
import VueGridLayout from 'vue-grid-layout'
 import ApexLineAreaChart from "@/components/charts/apex-chart/chartsquery/ApexLineAreaChart.vue"
 import ApexLineArea from "@/components/charts/apex-chart/chartsquery/ApexLine&Area.vue"
 import ApexMultipeBarChart from "@/components/charts/apex-chart/chartsquery/ApexMultipleBarChart.vue"
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BSpinner,
    BRow,
    BCol,
     GridLayout: VueGridLayout.GridLayout,
           GridItem: VueGridLayout.GridItem,
           ApexLineAreaChart,ApexLineArea,ApexMultipeBarChart
  },
  data() {
    return {
      dashboards:[],
      layout:[],
      changeDash:0,
      selecionado:'',
      breakpoints: {
      default() {
        return {
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        };
      },
    },
cols: {
      default() {
        return {
          lg: 12,
          md: 10,
          sm: 6,
          xs: 4,
          xxs: 2,
        };
      },
    }
    };
  },
  mounted() {
    this.getDashboards();
  },
  methods: {
    getDash:function(p){
        this.changeDash=1
        this.selecionado=p
        this.layout=p.layout
        this.changeDash=2
    },
    getDashboards: function () {
      const vm = this;
      const axios = require('axios');
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios
        .get('/api/v1/sel/bderbg', config)
        .then(function (response) {
          console.log(response.data);
          vm.dashboards = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>